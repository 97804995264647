import { dispatch } from '../dispatcher';
import * as constants from '../constants';
import moment from 'moment';

const ApiService=async (serviceName,params,successCallback,errorCallback)=>{
    const response = await fetch("config.json");
    const config = await response.json();   
    const url = `${config.API_URL}/${serviceName}`;
    fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        })
        .then(response => response.json())
        .then(data => {
            successCallback(data);
        })
        .catch((error) => {
            errorCallback(error);
        });
}


const loadStaticData = async (ref) => {
    debugger;
    let localState = ref.state;
    const response = await fetch("config.json");
    const config = await response.json();
    let param = { HotelGroupCode: config.HotelGroup };
    localState = dispatch(ref.state, { type: constants.requestStaticData });
    ref.setState({ ...localState });

    let hotels = [{ PropertyCode: 'G11', PropName: 'Fitzrovia Hotel' }, { PropertyCode: 'G10', PropName: 'Langham Court Hotel' }, { PropertyCode: 'G09', PropName: 'Strathmore Hotel' }]
    if (config.HotelGroup == "GG")
    {
        hotels = [{ PropertyCode : 'G01', PropName : 'Grange Clarendon Hotel' },
                { PropertyCode : 'G02', PropName : 'Grange Buckingham Hotel' },
                { PropertyCode : 'G03', PropName : 'Grange Portland Hotel' },
                // { PropertyCode : 'G04', PropName : 'Grange Lancaster Hotel' },
                { PropertyCode : 'G16', PropName : 'Grange Beauchamp Hotel' }] ; 
    }
    let srvrDt = moment(new Date()).format("DD MMM YYYY");
    let responseData = { Hotels: hotels, ServerDate: srvrDt, Status: "Success" }
    localState = dispatch(ref, { type: constants.receiveStaticData, response: responseData });
    ref.setState({ ...localState });
    // ApiService("GetStaticData",param,(data)=>{
    //     let response = data || {Status:'Failed'};  
    //     localState=dispatch(ref,{ type: constants.receiveStaticData,response});            
    //     ref.setState({ ...localState });
    // },(error)=>{
    //     localState=dispatch(ref,{ type: constants.receiveStaticData,response:null});          
    //     ref.setState({ ...localState });
    // });  

}
export {ApiService,loadStaticData}